.dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 166px);
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 0 48px;
  position: relative;
}

.dashboard__texts {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 48px;
  left: 48px;
}

.dashboard__texts-title {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: black;
  margin-bottom: 12px;
}

.dashboard__texts-help {
  font-size: 16px;
  line-height: 16px;
  color: #707070;
}

.dashboard-container {
  background-color: white;
  box-shadow: 0 3px 46px rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 48px;
  text-align: left;
  max-width: 368px;
}

.dashboard-container__title {
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 24px;
  color: black;
}

.dashboard-container__text-before {
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  margin-bottom: 48px;
  color: rgb(112, 112, 112);
}

.login-container__form-field-create {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(44, 113, 113, 1);
  color: white;
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  height: 48px;
  margin: 48px 0 0 0;
  cursor: pointer;
  border-radius: 8px;
}

.login-container__form-field-create:hover {
  background-color: rgb(34, 87, 87);
}

.login-container__form-field-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(44, 61, 113);
  color: white;
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  line-height: 16px;
  height: 48px;
  margin: 48px 0 0 0;
  cursor: pointer;
  border-radius: 8px;
}

.login-container__form-field-search:hover {
  background-color: #222f57;
}
