.nav {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
}

.nav-container {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.nav-container__items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
}

.nav-container__items > div:last-child{
  cursor: pointer;
}

.nav-container__item {
  position: relative;
  color: rgb(112, 112, 112);
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  margin-left: 60px;
}

.nav-container__item:hover span svg {
  animation: chevronStatus 0.2s forwards;
}

.nav-container__item a {
  font-family: "SF Pro Display Bold", sans-serif;
  color: rgb(112, 112, 112);
}

.nav-container__item:hover,
.nav-container__item a:hover {
  color: rgb(44, 61, 113);
}

.nav-container__item-icon {
  margin-left: 20px;
}

.nav-container__item-dropdown-container {
  position: absolute;
  top: 42px;
  left: -40px;
  background-color: white;
  width: 200px;
  margin-top: -26px;
  box-shadow: 20px 47px 41px rgba(0, 0, 0, 0.23);
}

.nav-container__item-dropdown-container a {
  color: rgb(112, 112, 112);
  text-decoration: none;
  font-family: "SF Pro Display Bold", sans-serif;
}

.nav-container__item-dropdown-item {
  color: rgb(112, 112, 112);
  font-family: "SF Pro Display Bold", sans-serif;
  font-size: 16px;
  text-align: left;
  padding: 10px 40px;
}

.nav-container__item-dropdown-item:first-child {
  margin-top: 40px;
}

.nav-container__item-dropdown-item:last-child {
  margin-bottom: 20px;
}

.nav-container__item-dropdown-item:hover {
  background-color: rgb(44, 61, 113);
}

.nav-container__item-dropdown-item:hover a {
  font-family: "SF Pro Display Bold", sans-serif;
  color: white;
}
