.print-document__select-data-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 48px;
}

.print-document__document-types-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 4.5%;
}

.print-document__container {
  background: white;
  border-radius: 12px;
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
  padding: 30px 48px;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
}

.print-document__container.labels,
.print-document__container.count {
  width: 65.5%;
}

.print-document__container.delivery-note,
.print-document__container.reclamation {
  width: 30%;
}

.print-document__texts-container {
  width: 100%;
  text-align: start;
  margin-bottom: 30px;
}

.print-document__texts-container h2 {
  font-size: 40px;
}

.print-document__texts-text {
  font-size: 16px;
  color: #707070;
}

.print-document__buttons-container,
.print-document__button-container {
  display: flex;
  gap: 24px;
}

.print-document__button-container {
  cursor: pointer;
}

.print-document__button-container:hover svg {
  box-shadow: 0px 3px 46px rgba(0, 0, 0, 0.23);
}

.print-document__button-container:hover h2 {
  color: rgb(44, 61, 113);
}

.print-document__button-container:hover p {
  color: rgba(44, 113, 113, 1);
}

.print-document__buttons-texts {
  text-align: start;
}

.print-document__buttons-texts h2 {
  margin-bottom: 12px;
}

/* Hitpoint */

.print-document__button-container {
  position: relative;
}

.hitPoint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

/* Modal */

.modal-body__info.bg-light-grey {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 12px;
  min-height: 350px;
  max-height: 350px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 0;
}

.modal__table-headers-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 12px 12px 12px 0;
  border-bottom: 1px solid black;
  text-align: start;
}

.modal__table-headers-container p {
  font-family: "SF Pro Display Bold";
}

.modal__table-rows-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: start;
}

.modal__table-row-container {
  width: 100%;
  display: flex;
  padding: 6px;
}

.col-5 {
  width: 5%;
}

.col-10 {
  width: 10%;
}

.col-15 {
  width: 15%;
}

.col-20 {
  width: 20%;
}
.col-25 {
  width: 25%;
}
.col-30 {
  width: 30%;
}
.col-35 {
  width: 35%;
}
.col-40 {
  width: 40%;
}
.col-45 {
  width: 45%;
}
.col-50 {
  width: 50%;
}
.col-55 {
    width: 55%;
  }
  .col-60 {
    width: 60% !important;
  }
  .col-65 {
    width: 65%;
  }
  .col-70 {
    width: 70%;
  }
  .col-75 {
    width: 75%;
  }
  .col-80 {
    width: 80%;
  }

.col-spacer {
  width: 6%;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

/* Search */
.modal-body-search__container {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  margin-top: 24px;
}

.modal-body-search__container input {
    width: 100%;
    height: 50px;
    background-color: #f6f6f6;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 24px;
    padding-left: 48px;
    font-size: 16px;
}

.modal-body-search__container svg {
  position: absolute;
  left: 12px;
  top: 14px;
}
